import * as React from "react";
import { Link } from "react-router-dom";
import { Box, makeStyles, Typography, Grid, Card, Avatar, CircularProgress } from "@material-ui/core";
import {
    TrackChanges as TargetIcon,
    DescriptionOutlined as DocumentsIcon,
    Description as TemplatesIcon,
    PersonOutlined as ConsultantIcon,
    OfflineBolt as AIIcon,
    AlternateEmail as MarketingIcon,
    List as RegistrationsIcon
} from "@material-ui/icons";
import calendarIcon from "../images/calendar-line.svg";
import timeSummaryIcon from "../images/timecard.svg";
import timeSheetIcon from "../images/timetable.svg";
import leadFormIcon from "../images/text-file-line.svg";
import opportunityIcon from "../images/widget.svg";
import useVisitAutomationWindow from "../hooks/queries/useVisitAutomationWindow";
import useVisitSummary from "../hooks/queries/useVisitSummary";
import { formatDate } from "../helpers/formatDate";
import { useAuth } from "../contexts/authContext";

const useStyles = makeStyles(theme => ({
    card: {
        padding: theme.spacing(2),
        height: "200px",
        width: "200px",
        border: "1px solid black",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tile: {
        width: "225px",
        height: "175px",
        background: "#eee",
        borderRadius: "15px",
        boxShadow:
            "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "15px",
        cursor: "pointer",
        textDecoration: "none",
        "&:hover": {
            marginTop: "-5px",
            boxShadow:
                "0 9px 6px -1px rgb(0 0 0 / 0.1), 0 7px 4px -2px rgb(0 0 0 / 0.1)",
        },
    },
    tileText: {
        color: "#150850",
        textDecoration: "none",
        fontWeight: "600",
        fontSize: "1rem",
        textAlign: "center",
    },
    imageIcon: {
        height: "40px",
        width: "40px",
    },
    fontIcon: {
        fontSize: "50px",
        color: "#7a5cf0",
    },
    headlineConsultant: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
        width: "450px",
        alignItems: "center",
        justifyContent: "center"
    },
    headlineData: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
        width: "200px",
        height: "200px",
    },
    headline: {
        borderRadius: '4px',
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        padding: theme.spacing(4),
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    headlineConsultantText: {
        alignItems: "center",
        justifyContent: "center"
    },

    consultantAvatar: {
        backgroundColor: "#F2EEFF",
        color: "#785AF3",
        height: "100px",
        width: "100px",
        marginRight: theme.spacing(3),
    },
    consultantIcon: {
        height: "80px",
        width: "80px",
    },
    cardTextContainer: {
        display: "flex",
        flexDirection: "column",
    },
    headlineValue: {
        fontWeight: 700,
    },
    figureHead: {
        textAlign: "center",
        fontWeight: 700,
        textTransform: "none",
        color: "#785AF3",
    },
    figureHeadPercent: {
        textAlign: "center",
        fontWeight: 700,
        textTransform: "none",
        color: "#785AF3",
        marginTop: theme.spacing(6),
    },
    figureHeadRegion: {
        textAlign: "center",
        fontWeight: 700,
        textTransform: "none",
        color: "#785AF3",
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(6),
    },
    figureDetail: {
        textAlign: "center",
        fontWeight: 500,
        textTransform: "none",
        color: "#785AF3",
    },
    figureFooter: {
        textAlign: "center",
        marginTop: theme.spacing(6),
    },
    spinnerContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

const Home = () => {
    const classes = useStyles();
    const { hasRole } = useAuth();
    const { data: visitAutomationData } = useVisitAutomationWindow();
    const visitSummaryData = useVisitSummary();   

    return (
        <>
            <Box
                display="flex"
                width="100%"
                justifyContent="center"
                flexDirection="row"
                flexWrap="wrap"
                gridGap="30px"
                marginBottom="30px"
                marginTop="30px"
            >
                <Link to="/opportunities" className={classes.tile}>
                    <img
                        className={classes.imageIcon}
                        alt="Opportunities Dashboard"
                        src={opportunityIcon}
                    />
                    <div className={classes.tileText}>Opportunities Dashboard</div>
                </Link>                
                <Link to="/leadform" className={classes.tile}>
                    <img
                        className={classes.imageIcon}
                        alt="Lead Form"
                        src={leadFormIcon}
                    />
                    <div className={classes.tileText}>Lead Form</div>
                </Link>
                <Link to="/complete-form/complaint" className={classes.tile}>
                    <img
                        className={classes.imageIcon}
                        alt="Complaint Form"
                        src={leadFormIcon}
                    />
                    <div className={classes.tileText}>Complaint Form</div>
                </Link>
                <Link to="/complete-form/OpportunityLost" className={classes.tile}>
                    <img
                        className={classes.imageIcon}
                        alt="Opportunity Lost Form"
                        src={leadFormIcon}
                    />
                    <div className={classes.tileText}>Opportunity Lost Form</div>
                </Link>
                <Link to="/time-sheet" className={classes.tile}>
                    <img
                        className={classes.imageIcon}
                        alt="Time Sheet"
                        src={timeSheetIcon}
                    />
                    <div className={classes.tileText}>Time Sheet</div>
                </Link>
                <Link to="/weekly-summary" className={classes.tile}>
                    <img
                        className={classes.imageIcon}
                        alt="Weekly Time Summary"
                        src={timeSummaryIcon}
                    />
                    <div className={classes.tileText}>Weekly Time Summary</div>
                </Link>
                <Link to="/visit-calendar" className={classes.tile}>
                    <img
                        className={classes.imageIcon}
                        alt="Visit Calendar"
                        src={calendarIcon}
                    />
                    <div className={classes.tileText}>Visit Calendar</div>
                </Link>
                <Link to="/documents" className={classes.tile}>
                    <DocumentsIcon className={classes.fontIcon} />
                    <div className={classes.tileText}>Documents</div>
                </Link>
                {hasRole("Targets") && (
                    <Link to="/targets" className={classes.tile}>
                        <TargetIcon className={classes.fontIcon} alt="Targets" />
                        <div className={classes.tileText}>Targets</div>
                    </Link>
                )}
                {hasRole("Templates") && (
                    <Link to="/templates" className={classes.tile}>
                        <TemplatesIcon className={classes.fontIcon} alt="Templates" />
                        <div className={classes.tileText}>Templates</div>
                    </Link>
                )}
                {hasRole("AI") && (
                    <Link to="/ai" className={classes.tile}>
                        <AIIcon className={classes.fontIcon} alt="AI Playground" />
                        <div className={classes.tileText}>AI playground</div>
                    </Link>
                )}
                {hasRole("Marketing") && (
                    <Link to="/marketing" className={classes.tile}>
                        <MarketingIcon className={classes.fontIcon} alt="Marketing" />
                        <div className={classes.tileText}>Marketing</div>
                    </Link>
                )}
                {hasRole("Registrations") && (
                    <Link to="/registrations" className={classes.tile}>
                        <RegistrationsIcon className={classes.fontIcon} alt="Registrations" />
                        <div className={classes.tileText}>Registrations</div>
                    </Link>
                )}
            </Box>

            {visitSummaryData.isLoading &&
                <div className={classes.spinnerContainer}>
                    <CircularProgress />
                </div>
            }

            {visitSummaryData.isError &&
                <Typography>{visitSummaryData.error}</Typography>
            }

            {visitSummaryData.data &&
                <Box
                    display="flex"
                    width="100%"
                    justifyContent="center"
                    flexDirection="row"
                    flexWrap="wrap"
                    gridGap="30px"
                    marginBottom="30px"
                    marginTop="30px"
                >

                    <Box display="flex" alignItems="center" className={classes.headlineConsultant}>

                        <Grid container direction="row" className={classes.headlineConsultantText}>
                            <Grid item>
                                <Avatar className={classes.consultantAvatar}>
                                    <ConsultantIcon className={classes.consultantIcon} />
                                </Avatar>
                            </Grid>
                            <Grid item>
                            <Typography className={classes.headlineValue} variant='h2'>{visitSummaryData.data.consultantName}</Typography>
                            <Typography variant='subtitle1'>{visitSummaryData.data.consultantJobTitle}</Typography>
                            </Grid>
                        </Grid>
                    </Box>


                    <Card className={classes.card}>
                        <div className={classes.cardTextContainer}>
                        <Typography variant="h1" className={classes.figureHead}>{visitSummaryData.data.consultantNextVisitDate}</Typography>
                        <Typography variant="h2" className={classes.figureDetail}>{visitSummaryData.data.consultantNextVisitTime}</Typography>
                        <Typography variant="h4" className={classes.figureDetail}>{visitSummaryData.data.consultantTickerTillNextVisit}</Typography>

                            <Typography variant="subtitle2" className={classes.figureFooter}>Next Scheduled Visit</Typography>
                        <Typography variant="subtitle2">{visitSummaryData.data.daysTillEndOfMonth} days until end of Month</Typography>
                        </div>
                    </Card>

                    <Card className={classes.card}>
                        <div className={classes.cardTextContainer}>
                        <Typography variant="h1" className={classes.figureHeadPercent}>{visitSummaryData.data.consultantMonthlyPercentage}%</Typography>
                        <Typography variant="h2" className={classes.figureDetail}>{visitSummaryData.data.consultantMonthlyVisitCount}/{visitSummaryData.data.consultantMonthlyTarget}</Typography>


                            <Typography variant="subtitle2" className={classes.figureFooter}>Monthly Visit Target</Typography>
                        <Typography variant="subtitle2">{visitSummaryData.data.daysTillEndOfMonth} days until end of Month</Typography>
                        </div>
                    </Card>

                    <Card className={classes.card}>
                        <div className={classes.cardTextContainer}>
                        <Typography variant="h1" className={classes.figureHeadPercent}>{visitSummaryData.data.consultantAnnualPercentage}%</Typography>
                        <Typography variant="h2" className={classes.figureDetail}>{visitSummaryData.data.consultantAnnualVisitCount}/{visitSummaryData.data.consultantAnnualTarget}</Typography>

                            <Typography variant="subtitle2" className={classes.figureFooter}>Annual Visit Target</Typography>
                        <Typography variant="subtitle2">{visitSummaryData.data.daysTillEndOfMonth} days until end of Month</Typography>
                        </div>
                    </Card>

                    <Card className={classes.card}>
                        <div className={classes.cardTextContainer}>
                        <Typography variant="h1" className={classes.figureHeadRegion}>{visitSummaryData.data.regionalMonthlyPercentage}%</Typography>

                            <Typography variant="subtitle2" className={classes.figureFooter}>Regional Visit Target</Typography>
                        <Typography variant="subtitle2">{visitSummaryData.data.daysTillEndOfMonth} days until end of Month</Typography>
                        </div>
                    </Card>
                </Box>
            }

            {visitAutomationData && (
                <Typography variant="h6" align="center" my={3}>Current Visit Automation is booking between {formatDate(visitAutomationData?.item1)} and {formatDate(visitAutomationData?.item2)}</Typography>
            )}
        </>
    );
};

export default Home;
